.storage-item {
  .form-label {
    margin-bottom: 0px;
  }
  input[type='radio'] {
    width: 16px;
    height: 16px;
    border-color: $primary-bg-color;
    border-radius: 100% !important;
    &:focus {
      border: 0px;
      box-shadow: none;
    }
  }
}
.form-check-input:checked[type='radio'] {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><circle r="3" fill="%23ffffff" cx="8" cy="8" /></svg>');
  background-color: $primary-bg-color;
  border: 1px solid $primary-bg-color;
  &:active {
    filter: none;
  }
}
