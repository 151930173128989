.page {
  .page-body {
    .new_application {
      .btn-list {
        .btn {
          background-color: $primary-bg-color;
          border-radius: $border-radius;
          font-family: 'Mulish';
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $bg-color;
          border: 0px;
          padding: 0.75em 3.5em;
          box-shadow: none;
        }
      }
      .card {
        background: transparent;
        border: 0px;
        .table-responsive {
          table {
            border-collapse: separate;
            border-spacing: 0 1em;

            tr {
              td {
                background-color: $secondary-bg-color;
                font-family: Mulish;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.24px;
                color: $text-color;

                .avatar.blue {
                  background-color: $avator-color;
                  text-transform: uppercase;
                  color: $bg-color;
                  border-radius: 100%;
                }
                .avatar.red {
                  background-color: $primary-btn-color;
                  text-transform: uppercase;
                  color: #ffffff;
                  border-radius: 100%;
                }
                span {
                  font-family: Mulish;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: -0.24px;
                  color: $text-color;
                }
              }
            }
          }
        }
      }
      .dropdown {
        min-width: 10em;
        .label {
          background-color: $secondary-bg-color;
          .caret {
            background-color: $bg-color;
          }
        }
        .options {
          background-color: $secondary-bg-color;
        }
      }
    }
  }
  #installation {
    .modal-content {
      background-color: $modal-bg;
      border-radius: 20px;
      height: 60vh;
      flex-direction: row;
      align-items: center;
      a {
        background-color: $primary-bg-color;
        color: $bg-color;
        font-family: Mulish;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
        border-radius: $border-radius;
        text-align: center;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.pre-table tr th {
  border-bottom: 1px solid $text-color;
  color: $text-color;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
  text-align: left;
}
tr td:first-child {
  border-top-left-radius: $border-radius;
}
tr td:last-child {
  border-top-right-radius: $border-radius;
}

tr td:first-child {
  border-bottom-left-radius: $border-radius;
}
tr td:last-child {
  border-bottom-right-radius: $border-radius;
}
