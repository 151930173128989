@media (min-width: 320px) and (max-width: 767px) {
  [class^='col-'] {
    width: 100%;
  }
  .form-label,
  .form-check-label,
  .form-control,
  .preform .preform-header span,
  .net_ic span {
    font-size: 14px !important;
    white-space: pre;
  }
  .buttons {
    .submit-btn,
    .prev-btn {
      font-size: 14px !important;
      padding: 0.5em 1em;
    }
  }
  .preform .preform-header {
    overflow-y: scroll;
  }
  .preform .preform-header::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .gap-5 {
    gap: 1em !important;
  }
  .page {
    .page-body {
      .new_application {
        .btn-list {
          .btn {
            padding: 0.75em 1.5em !important;
          }
        }
      }
    }
  }
  .preform .preform-header.flex-column {
    flex-direction: row !important;
  }
  .preform .preform-header.py-4 {
    padding: 1em !important;
  }
  .preform .preform-header.flex-wrap {
    flex-wrap: nowrap !important;
  }
}
