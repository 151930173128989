@import '~react-toastify/dist/ReactToastify.css';
@import './colors.scss';
@import './responsive.scss';

// all pages scss
@import 'pages/login/login';

@import 'pages/header/header';
@import 'pages/footer/footer';
@import 'pages/dashboard/dashboard';
@import 'pages/preForm/preform';

// all components scss
@import 'components/informationblock';
@import 'components/sidebar';
@import 'components/table';
@import 'components/input';
@import 'components/dropdown';
@import 'components/checkbox';
@import 'components/radiobutton';
@import 'components/datepicker';
@import 'components/buttons';
@import 'components/assignment';

.page-body {
  height: 85vh;
  overflow-y: scroll;
  padding: 2em 1em;
  margin-top: 0px;
  margin-bottom: 0px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.page-title {
  font-family: 'Mulish';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  color: $text-color;
}
@media (min-width: 1200px) {
  .container-xl {
    max-width: 100%;
  }
}
.error-message {
  color: $primary-btn-color;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.45px;
  margin: 0.5em 0em;
}
.vh-50 {
  height: 50vh;
}
#modal-report {
  background: rgba(0, 0, 0, 0.81);
  .modal-content {
    background: $secondary-bg-color;
    .modal-header {
      background: $secondary-bg-color;
    }
    textarea {
      background: $bg-color !important;
      padding-top: 1em;
    }
  }
}
.viewcomments {
  background-color: $bg-color;
  color: $primary-bg-color;
  border-radius: 5px;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
  padding: 5px 13px;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.loader-container {
  height: 50vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
