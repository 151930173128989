$bg-color: #ffffff;
$primary-bg-color: #1abc9c;
$secondary-bg-color: #efefef;
$avator-color: #3498db;
$primary-btn-color: #ce392c;
$text-color: #000000;
$border-radius: 10px;
$pending-btn: #fff0be;
$modal-bg: #d9d9d9;
$inactive-color: #5c6b73;
$datepicker-placeholder: #e5e5e5;
$commision_btn: #b7ebb3;
