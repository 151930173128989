.table-responsive {
  .all_btn {
    @mixin btn {
      border-radius: $border-radius;
      text-align: center;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      padding: 1em;
      cursor: pointer;
    }



    .role_btn {
      @include btn;
      background-color: $bg-color;
      color: $primary-btn-color;
      padding: 1em 3em;
      cursor: default;
      width: 145px;
    }

    .pending_btn {
      @include btn;
      background-color: $pending-btn;
      color: $primary-btn-color;
      padding: 1em 3em;
      width: 165px;
      border-radius: $border-radius;
    }

    .pending_btn.rework_status {
      background-color: rgba(206, 57, 44, 0.5);
      color: $text-color;
    }

    .pending_btn.approved_status {
      background: rgba(26, 188, 156, 0.5);
      color: $text-color;
    }

    .pending_btn.commission_status {
      background-color: $commision_btn;
      color: $text-color;
      width: auto;
    }

    .edit_btn {
      @include btn;
      background-color: $primary-bg-color;
      color: $bg-color;
      padding: 1em 2em;
      width: 117px;
    }

    .delete_btn {
      @include btn;
      color: $primary-btn-color;
      padding: 1em 1em;
    }
  }

  .filters {
    display: flex;
    justify-content: center;
    // background-color: red;
    align-items: center;



    .date-filters {
      // background-color: green;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      .form-control {
        // background-color: pink;
        width: 100%;
        margin-left: 5px;
      }

    }

    .search-bar,
    .filter-select,

    .date-filter {
      width: 100%;
      margin-right: 5px;
    }

    .form-control {
      width: 100%;
      padding: 8px 16px;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      border-radius: $border-radius;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

      &:focus {
        border-color: $primary-bg-color;
      }
    }

    .clear-btn {
      padding: 8px 16px;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      border-radius: $border-radius;
      cursor: pointer;
      border: none;
      margin-left: 5px;
      background-color: $secondary-bg-color;
      color: $text-color;
      width: 25%;
    }
  }

  .circuit {
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    color: $text-color;



    th {
      font-family: 'Mulish';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      color: $bg-color;
      background-color: $primary-bg-color;
      border-color: $bg-color;
      border: solid;
    }

    input {
      border: 1px solid $datepicker-placeholder !important;
      width: 100%;
      padding: 0.5em;
    }

    .dropdown {
      padding: 0.5em 0px;

      .label {
        border: 1px solid $datepicker-placeholder !important;
        padding: 0.5em;
        font-size: 14px;
        width: 6em;

        .caret {
          width: 16px;
          height: 16px;
          right: 5px;
        }
      }

      .options {
        border: 1px solid $datepicker-placeholder !important;
        border-radius: 0px;

        div {
          font-size: 14px;
        }
      }
    }

    .signature {
      td:not(:first-child) {
        vertical-align: top;
      }
    }
  }

  .download_btn {
    background-color: $primary-btn-color;
    padding: 0.8em;
    border-radius: $border-radius;
    color: $bg-color;
    cursor: pointer;
  }

  .circuit.operational {
    td {
      font-size: 14px;
      text-align: center;



      &:nth-child(2),
      &:nth-child(1) {
        text-align: left;
        padding-bottom: 10px;
      }
    }
  }
}