.page {
  header {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    background-color: $secondary-bg-color;
    .container-xl {
      .navbar-nav {
        .nav-item {
          background-color: $bg-color;
          border-radius: 10px;
          padding: 10px 18px;
          .avatar.blue {
            background-color: $avator-color;
            text-transform: uppercase;
            color: #ffffff;
            border-radius: 100%;
          }
          .avatar.red {
            background-color: $primary-btn-color;
            text-transform: uppercase;
            color: #ffffff;
            border-radius: 100%;
          }
          .admin_name {
            font-family: Mulish;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.24px;
            color: $text-color;
          }
        }
      }
    }
  }
}
