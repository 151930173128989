.information_block {
  flex: auto;
  .card {
    background-color: $secondary-bg-color;
    border-radius: $border-radius;
    box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.11);
    border: 0px;
    .card-body {
      .h1 {
        color: $text-color;
        font-family: Mulish;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.3px;
        text-transform: capitalize;
      }
      .subheader {
        color: $text-color;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
        text-transform: capitalize;
      }
    }
  }
}
