.login-page {
  height: 100vh;
  background-color: $bg-color;
  .page {
    .container {
      max-width: 26em;
      .card {
        background-color: $secondary-bg-color;
        border-radius: 20px;
        box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.1);
        padding: 1.82em 1em;
        .text-center {
          .navbar-brand {
            h2 {
              font-family: 'Mulish';
              color: $text-color;
              font-size: 30px;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.45px;
              margin-bottom: 0px;
              margin-top: 0.3em;
              margin-left: 0.3em;
            }
            .forgot-password {
              color: $primary-btn-color;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.3px;
            }
            .new-password {
              color: $text-color;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.3px;
            }
          }
        }
        .card-body {
          form {
            input {
              background-color: $bg-color;
              border-radius: 10px;
              height: 3.5em;
              box-shadow: none;
              border: 0px;
            }
            .form-footer {
              .btn {
                background-color: $primary-bg-color;
                border-radius: 10px;
                font-family: 'Mulish';
                box-shadow: none;
                height: 2.5em;
                color: $bg-color;
                font-size: 20px;
              }
              .send-password {
                font-size: 16px;
                margin-top: -0.5em;
              }
            }
            .forgot-password {
              a {
                color: $primary-btn-color;
                font-family: 'Mulish';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.3px;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
        .password-link {
          font-family: Mulish;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $text-color;
        }
      }
    }
  }
}
