.page {
  aside {
    background-color: $secondary-bg-color;
    .container-fluid {
      .d-flex {
        padding: 22px 22px 0px;
        &:hover {
          text-decoration: none;
        }
        .navbar-brand {
          font-family: Mulish;
          font-size: 25px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          letter-spacing: -0.45px;
          color: $text-color;
        }
      }
    }
    .navbar-nav {
      padding: 1em;
      .nav-item {
        .nav-link {
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.3px;
          color: $text-color;
          padding: 14px 21px !important;
        }
      }
      .nav-item.active {
        background-color: $primary-bg-color;
        border-radius: $border-radius;
        .nav-link {
          color: $bg-color;
        }
      }
    }
  }
}
