.input-group-flat {
  .form-input-group {
    flex: 1;
    #password {
      border-radius: 10px 0px 0px 10px;
    }
  }
  .input-group-text {
    background-color: $bg-color;
    border-radius: 0px 10px 10px 0px;

    box-shadow: none;
    border: 0px;
  }
}
.net_ic {
  border-radius: $border-radius;
  font-size: 16px;
  background-color: $bg-color;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
  font-family: Mulish;
  display: flex;
  flex-direction: row;
  span {
    padding-right: 0.5em;
  }
  .form-input-group {
    flex: 1;
    .text-input {
      padding-left: 0em;
    }
  }
}
.text-input {
  border-radius: $border-radius;
  padding: 0.8em;
  border: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
  font-family: Mulish;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.input-group > .form-control:focus,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus {
  border: 0px;
  box-shadow: none;
}
.form-label,
.form-check-label,
.form-control {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
}
textarea.form-control {
  border: 0px;
  border-radius: 20px;
}
.form-control {
  &:focus {
    border: 0px;
    box-shadow: none;
  }
}
.drawing_input {
  label {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  input {
    border: 1px solid $datepicker-placeholder !important;
  }
}
input[type='number'] {
  &:focus-visible {
    border: 0px;
    box-shadow: none;
    outline-offset: 0px;
    outline: 0px;
  }
}
.tags-input-container {
  background-color: #fff;
  border-radius: $border-radius;
  width: min(80vw, 600px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
}

.tag-item {
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  background-color: $primary-bg-color;
  color: $bg-color;
}
.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: $text-color;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 16px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  border: none;
  outline: none;
}

.assigneeComment {
  border: 1px solid #e5e5e5 !important;
  width: 100%;
  padding: 0.5em;
}
