.assignment {
  .assign_btn {
    border-radius: 10px;
    text-align: center;
    font-family: Mulish, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 0.5em 1em;
    cursor: pointer;
    background-color: $primary-bg-color;
    color: $bg-color;
    border: 0px;
  }

  .filters {
    display: flex;
    flex-wrap: wrap; /* Add flex-wrap for better handling on smaller screens */
    margin-bottom: 1em;

    .search-bar,
    .state-filter {
      width: 25%;
      margin-left: 5px;
    }

    .form-control {
      width: 100%;
      padding: 8px 16px;
      font-family: Mulish, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      border-radius: $border-radius;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      &:focus {
        border-color: $primary-bg-color;
      }
    }

    .search-btn,
    .clear-btn {
      padding: 8px 16px;
      font-family: Mulish, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      border-radius: $border-radius;
      cursor: pointer;
      border: none;
      margin-left: 1em;
    }

    .search-btn {
      background-color: $primary-bg-color;
      color: $bg-color;
    }

    .clear-btn {
      background-color: $secondary-bg-color;
      color: $text-color;
    }
  }
}

.dropdown-user {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: $secondary-bg-color;
  border-radius: $border-radius;
  text-align: left;
  .dropdown-user-toggle {
    background-color: $secondary-bg-color;
    color: $text-color;
    padding: 8px 16px;
    border-radius: $border-radius;
    cursor: pointer;
    font-family: Mulish, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    .caret {
      position: absolute;
      right: 10px;
    }
  }
  .dropdown-user-menu {
    position: absolute;
    z-index: 1;
    background-color: #efefef;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    top: 2em;
    border-top: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
    .dropdown-user-item {
      padding: 8px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      width: 100%;
      font-family: Mulish, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      color: $text-color;
      &:hover {
        background-color: $primary-bg-color;
        color: $bg-color;
      }
    }
    .dropdown-user-item-selected {
      background-color: $primary-bg-color;
      color: $bg-color;
    }
  }
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .filters {
    flex-direction: column; /* Stack filters vertically */
    align-items: flex-start;

    .search-bar,
    .state-filter {
      width: 100%; /* Full width for mobile */
      margin-left: 0;
      margin-bottom: 10px; /* Space between inputs */
    }

    .search-btn,
    .clear-btn {
      width: 100%; /* Full width for buttons */
      margin-left: 0;
      margin-bottom: 10px; /* Space between buttons */
    }
  }

  .dropdown-user .dropdown-user-toggle,
  .dropdown-user .dropdown-user-menu .dropdown-user-item {
    font-size: 14px; /* Adjust font size for mobile */
    padding: 6px 12px; /* Adjust padding for mobile */
  }

  .assign_btn {
    font-size: 14px; /* Adjust font size for mobile */
    padding: 0.5em; /* Adjust padding for mobile */
  }

  .form-control {
    font-size: 14px; /* Adjust font size for mobile */
    padding: 6px 12px; /* Adjust padding for mobile */
  }
}