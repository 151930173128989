.preform {
  background-color: $secondary-bg-color;
  border-radius: 20px;
  h4 {
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.3px;
    color: $text-color;
  }
  .divider {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .preform-header {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: scroll;
    span {
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
      white-space: pre;
    }
    span.active {
      color: $primary-bg-color;
    }
    span.inactive {
      color: $inactive-color;
      cursor: pointer;
    }
  }
  .preform-header::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .earth_pit {
    input {
      border: 1px solid $datepicker-placeholder !important;
    }
  }
  .note_div {
    h3 {
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.24px;
      color: $primary-bg-color;
      text-transform: uppercase;
    }
    ol {
      li {
        font-family: Mulish;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.24px;
        color: $text-color;
      }
    }
  }
  ul.nav.nav-tabs {
    margin-bottom: 0.5em;
    li {
      a {
        background-color: $bg-color;
        margin-right: 1em;
        border-radius: 10px;
        font-family: 'Mulish';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.3px;
        border: 0px;
        box-shadow: none;
        color: $primary-bg-color;
        padding: 0.5em 1em;
      }
      a.active {
        background-color: $primary-bg-color;
        color: $bg-color;
      }
    }
  }
}
