.damaged-item {
  .form-label {
    margin-bottom: 0px;
  }
  input[type='checkbox'] {
    border: 0px;
    width: 24px;
    height: 24px;
    border-radius: 5px !important;
    &:focus {
      border: 0px;
      box-shadow: none;
    }
  }
}

.form-check-input:checked[type='checkbox'] {
  background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="%231abc9c" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
  background-color: $bg-color;
  &:active {
    filter: none;
  }
}
