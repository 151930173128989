.datepicker {
  .form-label {
    margin-bottom: 0px;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  input[type='date'] {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    font-family: Mulish;
  }
  input[value='']::-webkit-datetime-edit {
    color: $datepicker-placeholder;
  }
  input:focus::-webkit-datetime-edit {
    color: $text-color;
  }
}
