.buttons {
  display: flex;
  justify-content: end;
  gap: 20px;
  .submit-btn {
    border-radius: $border-radius;
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 1em;
    cursor: pointer;
    background-color: $primary-bg-color;
    color: $bg-color;
    padding: 1em 2em;
    border: none;
    &:focus-visible {
      border: 0px;
      box-shadow: none;
      outline: none;
    }
  }
  .prev-btn {
    border-radius: $border-radius;
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 1em;
    cursor: pointer;
    background-color: $primary-btn-color;
    color: $bg-color;
    padding: 1em 2em;
    border: none;
    &:focus-visible {
      border: 0px;
      box-shadow: none;
      outline: none;
    }
  }
}
.modal-footer {
  .submit-btn,
  .submit-btn:active {
    background-color: $primary-bg-color;
    color: $bg-color;
    border-radius: $border-radius;
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    border: 0px;
    box-shadow: none;
  }
}
