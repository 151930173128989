.dropdown {
  position: relative;
  .label {
    background-color: $bg-color;
    padding: 0.8em;
    cursor: pointer;
    position: relative;
    border-radius: $border-radius;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    .caret {
      background-color: $secondary-bg-color;
      position: absolute;
      right: 0.8em;
      top: 0px;
      bottom: 0px;
      color: $primary-bg-color;
      border-radius: 5px;
      margin: auto;
    }
  }
  .place-holder {
    color: $modal-bg;
  }
  .options {
    position: absolute;
    border-top: none;
    background: $bg-color;
    width: 100%;
    z-index: 1;
    top: 35px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    div {
      padding: 6px 12px;
      cursor: pointer;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
    }
    .selected {
      color: $primary-bg-color;
    }
  }
}
